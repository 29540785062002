<template>
  <div>
    <span class="text" v-for="(text, $index) of getText()">
      <span v-html="text"></span>
      <input v-if="getText().length > $index + 1"
            :disabled="getIfAnswered($index)"
             :class="['form-control', { valid: isValid($index) }, { invalid: !isValid($index) }]"
             type="text"
             v-model="inputs[$index]"
             @change="handleBlur($index)" />
      <span class="text-success" v-if="isValid($index)">{{ $t('lab.correct') }}: {{ correctAnswer($index) }}</span>
      <span class="text-danger" v-else>{{ $t('lab.incorrect') }}!, {{ $t('lab.correct') }}: <span class="text-success">{{ correctAnswer($index) }}</span><br /></span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'blank-question',

    props: ['question', 'index', 'validated-answers'],

    data() {
      return {
        selected: null,
        inputs: []
      }
    },

    watch: {
      question() {
        this.inputs = [];
      },

      validatedAnswers(val) {
        for(const input of val) {
          this.inputs[input.answer_id] = input.answer_text;
        }
      }
    },

    methods: {
      correctAnswer($index) {
        if(this.getIfAnswered($index) && this.getIfAnswered($index).correct_answers) {
          return this.getIfAnswered($index).correct_answers[$index].answer;
        }

        return false;
      },

      getText() {
        return this.question.text.split('[]');
      },

      handleBlur($index) {
        this.$emit('answer', this.question.questionID, $index, this.inputs[$index]);
      },

      isValid($index) {
        const answer = this.getIfAnswered($index);
        return answer && answer.is_correct == 1;
      },

      getIfAnswered($index) {
        return this.validatedAnswers.find(o => o.answer_id == $index);
      }
    }
  };
</script>

<style scoped>
  .valid {
    border: 3px solid #4cff00 !important;
  }
  .invalid {
    border: 3px solid #ff000a !important;
  }
  .text p {
    display: inline;
    margin: 0;
    padding: 0;
  }
  .vb-body p{
    font-size: 1.2rem !important;
    line-height: 1.6;
  }
  .form-control {
    width: auto !important;
    display: inline !important;
    border-color: #557c84;
  }
  .help-words li{
    display: inline;
    list-style-type: disc;
    color: #557c84;
  }
  .help-words li + li::before {
    content: " · ";
  }
</style>

<template>
    <div class="lab-instruction">
      <!-- Instruction start -->
      <h3 class="text-center mt-4">{{ $t('lab.instruction.title') }}</h3>
      <p class="link-red text-center mb-0">{{ $t('lab.instruction.warning') }}</p>
      <div class="note test-body mb-0">
        <div class="note-body" v-html="instructionText"></div>
        <div v-html="instructionExercise"></div>
      </div>
      <!-- Instruction end -->
    </div>
</template>

<script>
  export default {
    name: 'instruction',

    props: ['instructionText', 'instructionExercise'],
  };
</script>

<style scoped>

</style>

<template>
  <div>
    <p v-html="question.text"></p>
    <ul>
      <li v-for="answer of answers">
        <div :class="['form-check',
                        { valid: getIfAnswered(question.questionID) && isValid(question.questionID, answer.answer_id) },
                        { invalid: getIfAnswered(question.questionID) && !isValid(question.questionID, answer.answer_id) }]">
          <input :disabled="getIfAnswered(question.questionID)"
                 type="radio" :name="'true-false-'+question.questionID"
                 class="form-check-input"
                 @input="handleCheck(question.questionID, answer.answer_id)"
                 :checked="getIfAnswered(question.questionID) && getIfAnswered(question.questionID).answer_id == answer.answer_id"
                 :value="answer.answer_id === 1"
                 :id="'answer-'+answer.answer_id+question.questionID">
          <label class="form-check-label" :for="'answer-'+answer.answer_id+question.questionID">
            {{ answer.answer_text }}
            <span v-if="getIfAnswered(question.questionID) && isValid(question.questionID, answer.answer_id) && getIfAnswered(question.questionID).answer_id == answer.answer_id"
                  class="text-uppercase ml-5">{{ $t('lab.correct') }}!</span>
            <span v-else-if="getIfAnswered(question.questionID) && !isValid(question.questionID, answer.answer_id) && getIfAnswered(question.questionID).answer_id == answer.answer_id"
                  class="text-uppercase ml-5">{{ $t('lab.incorrect') }}!</span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'true-false',

    props: ['question', 'index', 'validated-answers'],

    data() {
      return {
        answers: [
          {
            answer_id: 1,
            answer_text: 'True'
          },
          {
            answer_id: 0,
            answer_text: 'False'
          }
        ]
      }
    },

    methods: {
      handleCheck(questionId, value) {
        this.$emit('answer', questionId, value);
      },

      isTrue(questionId) {
        return this.getIfAnswered(questionId).correct_answers == 1;
      },

      isValid(questionId, answerId) {
        return answerId == this.isTrue(questionId);
      },

      getIfAnswered(questionId) {
        return this.validatedAnswers.find(o => parseInt(o.question_id) === parseInt(questionId))
      }
    }
  };
</script>

<style scoped>
  .form-check-label {
    cursor: pointer;
  }
  .valid label {
    color: #4cff00 !important;
    font-weight: bolder;
  }
  .invalid label {
    color: #ff000a !important;
    font-weight: bolder;
  }
  .vb-body p{
    font-size: 1.2rem !important;
    line-height: 1.6;
  }
  ul {
    display: inline;
  }
  ul li{
    display: inline;
    list-style-type: disc;
    color: #557c84;
  }
  ul li + li::before {
    content: "";
  }
  .form-check-input{
    position: relative;
  }
</style>

<template>
    <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                    </div>
                </div>

                <!-- unit -->
                <section class=" px-0 mx-0 px-xl-5 mx-xl-5 mb-5 h-100 pb-5">
                    <div class="row justify-content-center">
                        <div class="col-12 mb-5">
                            <h2 class="text-center mt-5 mt-sm-0">{{ $t('lab.ug-language-lab') }}</h2>
                        </div>
                    </div>
                    <div v-if="session === null" class="row justify-content-center mb-5 text-center">
                      <div v-if="canSendRequest" class="col-12">
                         
                        <!-- <button class="btn btn-red btn-send-request" @click="sendRequest">{{ $t('lab.send-request') }}</button> -->
                      </div>
                      <div v-else class="col-12">
                        <h3>
                          {{ $t('lab.you-cant-send-request') }}
                        </h3>
                      </div>
                    </div>
                  <div v-else-if="!session.request_approved_by" class="row justify-content-center mb-5 text-center">
                    <div class="col-12">
                      <h3>{{ $t('lab.wait-for-confirmation') }}</h3>
                    </div>
                  </div>
                  <div v-else-if="finished" class="row justify-content-center mb-5 text-center">
                    <div class="col-12">
                      <div class="bg-light b-30 shadow-1 p-4 mt-5 pt-5" style="min-height:42rem">
                        <h3>{{ $t('lab.already-finished') }}</h3>
                        <h4>{{ $t('lab.point') }}: {{ totalPoint }}</h4>
                        <h4>{{ $t('lab.finish-date') }}: {{ finishDate }}</h4>
                      </div>
                    </div>
                  </div>
                    <div v-else-if="exercise" class="row justify-content-center mb-5">
<!--                        <div class="col-md-8 col-lg-9 mb-5">-->
                        <div class="col-md-12 mb-5">

                            <h4>{{ $t('lab.total-questions') }}: {{ unit.total_questions }}</h4>
                            <h4>{{ $t('lab.correct-answers') }}: {{ session.correct_answers }}</h4>
                            <h4>{{ $t('lab.total-points') }}: {{ parseFloat(parseFloat(session.correct_answers).toPrecision(2) / parseFloat(unit.total_questions)).toPrecision(2) }}</h4>
                            <div class="mb-3 mt-2">
                                <div class="progress position-relative" style="height: 20px;">
                                    <span class="position-absolute q-count">{{ exercise.position }}/{{ exercise.total_exercises }}</span>
                                    <div class="progress-bar bg-progress"
                                        role="progressbar"
                                        :style="'width: '+statusBar+'%'"
                                        :aria-valuenow="statusBar"
                                        aria-valuemin="0"
                                        :aria-valuemax="exercise.total_exercises">
                                    </div>
                                </div>
                            </div>
                            <h4 v-if="exercise.type">{{ $t('lab.type') }}: {{ exercise.type }}</h4>
                            <div class="bg-light b-30 shadow-1 p-4 mt-5" style="min-height:42rem">
                                <instruction
                                  v-if="exercise.instruction"
                                  :instruction-text="exercise.instruction"
                                  :instruction-exercise="exercise.exercise"></instruction>

                                <div class="note test-body mb-0">
                                  <div class="audio" v-if="exercise.audio_link">
                                    <audio controls>
                                      <source :src="exercise.audio_link" type="audio/mpeg">
                                      Your browser does not support the audio element.
                                    </audio>
                                  </div>
                                </div>

                              <div class="note test-body mb-0">
                                <div class="note-body ">
                                  <ol type="1">
                                    <li v-for="(question, $index) of exercise.questions">
                                      <multiple-choice
                                        @answer="answerMultiple"
                                        :key="$index"
                                        :index="$index"
                                        :question="question"
                                        :validated-answers="validatedAnswers"
                                        v-if="question.type.name === 'Multiple'"></multiple-choice>
                                      <blank-question
                                        @answer="answerBlankQuestion"
                                        :key="$index"
                                        :index="$index"
                                        :question="question"
                                        :validated-answers="validatedAnswers"
                                        v-if="question.type.name === 'Blanks'"></blank-question>
                                      <true-false
                                        @answer="answerTrueFalse"
                                        :key="$index"
                                        :index="$index"
                                        :question="question"
                                        :validated-answers="validatedAnswers"
                                        v-if="question.type.name === 'True/False'"></true-false>
                                    </li>
                                  </ol>
                                </div>
                              </div>

                                <div class="note-footer text-center">
                                  <button :disabled="loading" @click="handleCheckClick" class="btn btn-primary-red-10">{{ $t('lab.check') }}</button>
                                </div>
                            </div>
                        </div>

                        <!--<div class="col-md-4 col-lg-3 mb-5 mb-md-0">
                            <div class="bg-light b-30 shadow-1 px-4 py-4" >
                            <div class="heading bg-light-blue mb-3">
                                <h3 class="mb-0 text-blue">Virtual Notebook</h3>
                            </div>
                                <div class="note p-3 mb-0">
                                    <div class="note-body vb-body">
                                        <p>მოცემული სივრცე შეგიძლიათ გამოიყენოთ როგორც
                                            ელექტრონული რვეული, სადაც შესაძლებელია
                                            თქვენთვის საინტერესო/ახალი ლექსიკური
                                            ერთეულების ჩანიშვნა.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import LabResource from '../api/labResource';
import Instruction from '../components/lab/instruction';
import MultipleChoice from '../components/lab/multiple-choice';
import BlankQuestion from '../components/lab/blank-question';
import TrueFalse from '../components/lab/true-false';

const labResource = new LabResource();

export default {
  name:'lab-unit',
  components: { TrueFalse, BlankQuestion, MultipleChoice, Instruction, sidebar, appHeader, pageHeader },
  computed: {
    pageData() {
      return {
          title: this.$t('lab.title'),
          breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
          },
          {
          icon: '',
          title: this.$t('lab.title'),
          link: '/lab',
          isActive: true,
          }],
      };
    },

    statusBar() {
      let completedPercent = 0;
      if(this.exercise) {
        completedPercent = this.exercise.position / this.exercise.total_exercises * 100;
      }

      return parseInt(completedPercent);
    },
  },

  mounted() {
    this.loadUnit();
    this.sendRequest(this.$route.params.unitId) 
  },

  data() {
    return {
      unit: null,
      session: null,
      canSendRequest: false,
      exercise: null,
      answers: [],
      finished: false,
      finishDate: '',
      totalPoint: 0,
      checked: false,
      loading: false,
      validatedAnswers: []
    }
  },

  watch: {
    session(value) {
      if(value && value.request_approved_by) {
        this.loadCurrentExercise();
      } else if(value && !value.request_approved_by) {
        setTimeout(function() {
          window.location.reload();
        }, 10000);
      }
    }, 

    

  },

 


  methods: {
    getPoint() {
      return parseFloat(this.session.current_answers / this.unit.total_questions).toPrecision(2);
    },

    answerBlankQuestion(questionId, answerId, value) {
      let answer = this.answers.find(o => o.answer_id == answerId && o.question_id == questionId);
      if(answer) {
        answer.value = value;
      } else {
        this.answers.push({
          question_id: questionId,
          answer_id: answerId,
          value: value
        });
      }
    },

    answerMultiple(questionId, answerId) {
      let answer = this.answers.find(o => o.question_id == questionId);

      if(answer) {
        answer.question_id = questionId;
        answer.answer_id = answerId;
      } else {
        this.answers.push({
          question_id: questionId,
          answer_id: answerId,
        })
      }
    },

    answerTrueFalse(questionId, value) {
      let answer = this.answers.find(o => o.question_id == questionId);

      if(answer) {
        answer.question_id = questionId;
        answer.answer_id = value;
      } else {
        this.answers.push({
          question_id: questionId,
          answer_id: value,
        })
      }
    },

    handleCheckClick() {
      if(this.exercise.questions.length === 0) {
        this.loadNextExercise();
        return;
      }

      if(this.validatedAnswers.length > 0) this.loadNextExercise();
      else this.answer();
    },

    answer() {
      this.loading = true;
      labResource.answer(this.unit.unitID, this.exercise.exerciseID, this.answers)
        .then(response => {
          //this.exercise = response.data.data;
          //this.loadNextExercise();
          const data = response.data.data;
          this.validatedAnswers = data.answers;
          this.session.correct_answers = data.correct_answers;
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error, true, this.$t('lab.close'));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    exerciseLoaded(response) {
      if(response.data.data.finished) {
        this.finished = true;
        this.finishDate = response.data.data.finish_date;
        this.totalPoint = response.data.data.points;
      } else {
        this.exercise = response.data.data;
        this.answers = [];
      }
    },

    loadNextExercise() {
      this.loading = true;
      labResource.loadNextExercise(this.unit.unitID)
        .then(response => {
            this.exerciseLoaded(response);
            this.validatedAnswers = [];
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error);
        })
      .finally(() => {
        this.loading = false;
      })
    },

    loadCurrentExercise() {
      labResource.loadCurrentExercise(this.unit.unitID)
      .then(response => {
        this.exerciseLoaded(response);
        this.loadResults();
      })
    },

    sendRequest(unitID) {
      labResource.sendRequest(unitID)
      .then(response => {
        this.session = response.data.data;
        this.notifySuccessMessage($t('lab.request-successfully-sent'))
      })
    },

    loadUnit() {
      this.loading = true;
      labResource.loadUnit(this.$route.params.unitId)
        .then(response => {
          this.unit = response.data.data.unit;
          this.session = response.data.data.session;
          this.canSendRequest = response.data.data.can_send;
        })
        .catch(error => {
          this.$router.push('/lab');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadResults() {
      this.loading = true;
      labResource.loadResults(this.unit.unitID)
        .then(response => {
          const data = response.data.data;
          this.validatedAnswers = data.answers;
          this.session.correct_answers = data.correct_answers;
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>
<style scoped>
  .note-body > ol > li {
    margin-bottom: 40px;
  }

  .btn-send-request{
    width: 150px !important;
    max-width: 150px;
  }

.q-count{
    top: 99%;
    left: 50%;
    transform: translate(-50%,-50% );
    height: 20px;
    font-size: 1.3rem;
}
.progress{
    background-color: rgba(82,82,82,.05)
}
.bg-progress{
    background-color: rgba(41, 185, 25,.9);
}
.test-body{
    max-height: unset;
}
.vb-body{
    min-height: 46rem;
}
.vb-body p{
    font-size: 1.2rem !important;
    line-height: 1.6;
}
.form-control{
    width: auto;
    display: inline;
}
.help-words li{
    display: inline;
    list-style-type: disc;
    color: #557c84;
}
.help-words li + li::before {
  content: " · ";
}
.form-check-input{
    position: relative;
}

</style>

<template>
  <div>
    <p v-html="question.text"></p>
    <ol type="a">
      <li v-for="(answer, $index) of question.answers">
        <div :class="['form-check',
                        { valid: getIfAnswered(question.questionID) && isValid(question.questionID, answer.answerID) },
                        { invalid: getIfAnswered(question.questionID) && !isValid(question.questionID, answer.answerID) }]">
          <input type="radio"
                 :disabled="getIfAnswered(question.questionID)"
                 :name="'multiple-'+question.questionID"
                 class="form-check-input"
                 @input="handleCheck(question.questionID, answer.answerID)"
                 :value="answer.answerID"
                 :checked="getIfAnswered(question.questionID) && getIfAnswered(question.questionID).answer_id == answer.answerID"
                 :id="'answer-'+answer.answerID">
          <label class="form-check-label" :for="'answer-'+answer.answerID">
            <span v-html="answer.answer"></span>
            <span v-if="getIfAnswered(question.questionID) && isValid(question.questionID, answer.answerID) && getIfAnswered(question.questionID).answer_id == answer.answerID"
                                      class="text-uppercase ml-5">{{ $t('lab.correct') }}!</span>
            <span v-else-if="getIfAnswered(question.questionID) && !isValid(question.questionID, answer.answerID) && getIfAnswered(question.questionID).answer_id == answer.answerID"
                  class="text-uppercase ml-5">{{ $t('lab.incorrect') }}!</span>
          </label>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
  export default {
    name: 'multiple-choice',

    props: ['question', 'index', 'validated-answers'],

    methods: {
      handleCheck(questionId, answerId) {
        this.$emit('answer', questionId, answerId);
      },

      isValid(questionId, answerId) {
        const answer = this.validatedAnswers.find(o => o.question_id == questionId);
        return answer && answer.correct_answers.answerID == answerId ? true : false;
      },

      getIfAnswered(questionId) {
        return this.validatedAnswers.find(o => o.question_id == questionId)
      }
    }
  };
</script>

<style scoped>
  .form-check-label {
    cursor: pointer;
  }
  .valid label {
    color: #4cff00 !important;
    font-weight: bolder;
  }
  .invalid label {
    color: #ff000a !important;
    font-weight: bolder;
  }
  .vb-body p{
    font-size: 1.2rem !important;
    line-height: 1.6;
  }
  .help-words li{
    display: inline;
    list-style-type: disc;
    color: #557c84;
  }
  .help-words li + li::before {
    content: " · ";
  }
  .form-check-input{
    position: relative;
  }
</style>
